import React from "react";
import App from "./app";
import Header from "./header";
import * as styles from "../styles/global.css";

const IndexPage = () => {
  return (
    <main style={styles}>
      <div className="wrapper">
        <title>francis webb</title>
        <Header />
        <App />
      </div>
    </main>
  );
};

export default IndexPage;
